import React from 'react';
import '../style.scss';
import { AppProps } from 'next/app';
import 'regenerator-runtime/runtime';
import { Store } from 'redux';
import Script from 'next/script';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { DefaultSeo } from 'next-seo';
import { clarity } from 'react-microsoft-clarity';
import withReduxStore from '../redux/with-redux-store';
import { MantineProvider } from '@mantine/core';
import { ApolloProvider } from '@apollo/client';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { Alatsi, Open_Sans, Merriweather } from '@next/font/google';
import NextNProgress from 'nextjs-progressbar';
import { useApollo } from '../lib/apollo-client';
import FeedbackWidget from '../components/FeedbackWidget';
import CheckToken from '../components/CheckToken';
import VersionContext from '../contexts/VersionContext';
import * as Types from '../declarations';
import { withVersion } from '../lib/withVersion';
interface Props extends AppProps {
  // Component: FC
  // pageProps: any
  reduxStore: Store;
  version: Types.VERSION;
}
const alatsi = Alatsi({
  weight: ['400'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-alatsi'
});
const openSans = Open_Sans({
  weight: ['400', '700'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-open-sans'
});
const merriweather = Merriweather({
  weight: ['300', '400', '700'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-merriweather'
});
const MyApp = ({
  Component,
  version,
  reduxStore,
  pageProps
}: Props) => {
  const persistor = persistStore(reduxStore);
  const client = useApollo();

  // const router = useRouter()
  // const { asPath } = router

  // const isAdminRoute = asPath.startsWith('/admin')

  React.useEffect(() => {
    // MatomoNext.init({
    // 	url: 'https://analytics.samt.st/piwik.php',
    // 	siteId: version === Types.VERSION.TREKKING ? '6' : '7',
    // 	excludeUrlsPatterns: [/^\/admin/],
    // })
    clarity.init(process?.env?.NEXT_PUBLIC_SITE_VERSION as Types.VERSION === Types.VERSION.TREKKING ? 'p9kin2s5sa' : 'ot8dzpegxn');
  }, []);

  // React.useEffect(() => {
  // 	// Dynamically configure the Plausible domain
  // 	const plausibleDomain =
  // 		(process?.env?.NEXT_PUBLIC_SITE_VERSION as Types.VERSION) ===
  // 		Types.VERSION.TREKKING
  // 			? 'guidedtrekking.com'
  // 			: 'guidedpeaks.com'

  // 	const { enableAutoPageviews } = plausible({
  // 		domain: plausibleDomain,
  // 		trackLocalhost: false,
  // 	})

  // 	enableAutoPageviews()
  // }, [version])

  const seo = {
    siteName: version === Types.VERSION.TREKKING ? 'Guided Trekking' : 'Guided Peaks'
  };
  return (
    // <main className={alatsi.className}>
    <main className={`${alatsi.variable} ${openSans.variable} ${merriweather.variable}`} data-sentry-component="MyApp" data-sentry-source-file="_app.tsx">
			<NextNProgress color={'#b3490a'} options={{
        showSpinner: false
      }} data-sentry-element="NextNProgress" data-sentry-source-file="_app.tsx" />
			{/* <Insights /> */}
			<ApolloProvider client={client} data-sentry-element="ApolloProvider" data-sentry-source-file="_app.tsx">
				{/* 
     // @ts-ignore */}
				<Provider store={reduxStore} data-sentry-element="Provider" data-sentry-source-file="_app.tsx">
					{/* <IsSsrMobileContext.Provider value={pageProps.isSsrMobile}> */}
					<PersistGate loading={<Component {...pageProps} />} persistor={persistor} data-sentry-element="PersistGate" data-sentry-source-file="_app.tsx">
						{/* <Script
       src="https://www.googletagmanager.com/gtag/js?id=G-HZHJQYR9R7"
       strategy="afterInteractive"
       /> */}
						{/* <Script id="google-analytics" strategy="afterInteractive">
       {`
       window.dataLayer = window.dataLayer || [];
       function gtag(){dataLayer.push(arguments);}
       gtag('js', new Date());
       	gtag('config', 'G-HZHJQYR9R7');
       `}
       </Script> */}

						{/* Your Plausible Analytics script */}
						<Script src="https://plausible.io/js/script.hash.outbound-links.pageview-props.tagged-events.js" data-domain={process?.env?.NEXT_PUBLIC_SITE_VERSION as Types.VERSION === Types.VERSION.TREKKING ? 'guidedtrekking.com' : 'guidedpeaks.com'} strategy="lazyOnload" data-sentry-element="Script" data-sentry-source-file="_app.tsx" />
						<Script id="plausible-init" strategy="lazyOnload" data-sentry-element="Script" data-sentry-source-file="_app.tsx">
							{`window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`}
						</Script>

						<MantineProvider withGlobalStyles withNormalizeCSS theme={{
              /** Put your mantine theme override here */
              colorScheme: 'light',
              primaryShade: 8
            }} data-sentry-element="MantineProvider" data-sentry-source-file="_app.tsx">
							{/* {!isAdminRoute && (
        <GoogleAnalytics
        	gaMeasurementId={
        		version === Types.VERSION.TREKKING
        			? 'G-HZHJQYR9R7'
        			: 'G-9FW99Y56EX'
        	}
        	trackPageViews
        />
        )} */}
							{/* <PlausibleProvider
        domain={
        	(process?.env?.NEXT_PUBLIC_SITE_VERSION as Types.VERSION) ===
        	Types.VERSION.TREKKING
        		? 'guidedtrekking.com'
        		: 'guidedpeaks.com'
        }
        trackLocalhost={false}
        > */}
							{<VersionContext>
									<DefaultSeo openGraph={{
                  type: 'website',
                  locale: 'en_US',
                  siteName: seo.siteName
                }} />
									<ModalsProvider>
										<Component {...pageProps} />
									</ModalsProvider>
									<FeedbackWidget />
									<CheckToken />
									<Notifications position="top-right" />
								</VersionContext>}
							{/* </PlausibleProvider> */}
						</MantineProvider>
					</PersistGate>
					{/* </IsSsrMobileContext.Provider> */}
				</Provider>
			</ApolloProvider>
		</main>
  );
};
MyApp.getInitialProps = withVersion(async context => {
  const {
    version
  } = context;
  return {
    version
  };
});
export default withReduxStore(MyApp);