import gql from 'graphql-tag'

export namespace Fragments {
	export const UploadFragment = gql`
		fragment UploadFields on Upload {
			id
			resourceId
			resourceType
			filename
			uploadedBy
			source
			attributionText
			attributionLink
			isDisplayedInGallery
			dimensionsOriginal
			dimensionsSmall
			dimensionsMedium
			dimensionsLarge
		}
	`

	export const JSONDataFragment = `
		alternativeNames
		trekOrClimb
		description
		highLevelInfo {
			trekRoute {
				routeType
				startPlace
				endPlace
			}
			climbRoute {
				startPlace
				endPlace
				howManyDaysTotal
				totalGainFromStart
				approach
			}
			distances {
				total {
					days
					distance
					days
					gain
					highestPoint
				}
				daily {
					time
					distance
					elevationGain
				}
			}
			walkIn {
				distanceOneWay
				totalGain
				numDaysIn
				numDaysOut
			}
			climb {
				maxElevation
				howManyDays
				elevationGainFromBaseCamp
				numberOfHighCamps
				highCampNote
			}
			cost {
				solo {
					range
				}
			}
			whenToGo {
				bestMonths
				note
			}
			guideNeeded {
				description
			}
		}
		countries
		continent
		itinerary {
			description
			days {
				dayNum
				title
				description
				totalElevationChange
				destinationElevation
				totalTime
				distance
			}
			climbSections {
				name
				elevation
				isSummit
				isBaseCamp
				description
			}
		}
		weather {
			description
			monthlyWeatherData {
				monthNo
				temperature
				rainfall
			}
		}
		gear {
			description
		}
		services {
			description

			accommodation
			food
			guides
			porters
			medical
			gear
			transport
			communications
			atms
			shops
		}
		accommodation {
			types
			description
			pictures
		}
		route {
			description
			locations {
				name
				longitude
				latitude
				type
			}
			routeLines {
				type
				linePoints
			}
		}
		permits {
			description
			permits {
				name
				costLocal
				cost
				description
				availability
			}
		}
		access {
			description
			pictures
		}
		videos
		variations {
			name
			pictures
			description
			daysExtra
		}
		# // todo: remove this
		similar
		faqs {
			question
			answer
		}
		groups
		guidedLinks {
			agencyName
			link
			cost
			costUpper
		}
		trekDifficulty {
			individualScores {
				distance
				elevationGain
				terrain
				altitude
				climaticConditions
				technicalSkills
				supportFacilities
			}
			total
			average
		}
		climbDifficulty {
			simple
		}
	`

	export const _TrekFields = `
		id
		uniqueTripId
		version
		name
		slug
		jsonData {
			${JSONDataFragment}
		}
		isValidHoldingPage
		isValidCompletePage
		isApproved
		publishedFrom
		uploads {
			id
			resourceId
			resourceType
			isDisplayedInGallery
			filename
			source
			attributionText
			attributionLink
		}
		similarTrips {
			id
			uploads { id}
			name
		}
	`

	export const TrekFragment = gql`
		fragment TrekFields on Trek {
			${_TrekFields}
		}
	`

	export const TrekStatusFragment = gql`
		fragment TrekStatusFields on TrekStatus {
			${_TrekFields}
		}
	`

	export const ArticleFragment = `
		id
		version
		title
		slug
		liveJson {
			content
			seoDescription
			coverImage
			subtitle
			tags
		}
		isPublished
		createdAt
		updatedAt
	`

	export const CountryFragment = `
		id
		version
		isoCode
		slug
		json {
			blurb
			mainRegionsSummary
			highlights {
				mostPopular
				lesserKnown
				mostDifficult
			}
			whenToClimb
		}
		isPublished
	`
}

export namespace Queries {
	export const GET_TREK_STATUS = gql`
		query GetTrekStatus($id: Int!) {
			getTrekStatus(id: $id) {
				${Fragments._TrekFields}
				missingRequiredHoldingFields {
					field
					editSection
				}
				missingRequiredFields {
					field
					editSection
				}
				missingNonRequiredFields {
					field
					editSection
				}
				deltas {
					draft {
						${Fragments.JSONDataFragment}
					}
					live {
						${Fragments.JSONDataFragment}
					}
				}
			}
		}
	`

	export const GET_UPLOADS = gql`
		query GetUploads($version: String!) {
			uploads(version: $version) {
				...UploadFields
			}
		}
		${Fragments.UploadFragment}
	`

	export const GET_MY_UPLOADS = gql`
		query GetMyUploads($tripSlug: String!) {
			getMyUploads(tripSlug: $tripSlug) {
				id
				isApproved
			}
		}
	`

	export const GET_SIGNED_URL = gql`
		query GetSignedURL($currentFileData: String!) {
			getSignedURL(currentFileData: $currentFileData) {
				success
				signedURL
				key
			}
		}
	`

	export const GET_CHAT_GPT_ANSWER = gql`
		query GetChatGPTAnswer($prompt: String!) {
			getGPTAnswer(prompt: $prompt)
		}
	`

	export const GET_UPLOADS_FOR_APPROVAL = gql`
		query GetUploadsForApproval {
			getUploadsNeedingApproval {
				items {
					id
					# uniqueTripId
					resourceId
					version
					attributionText
					attributionLink
					source
					uploadedBy
					tripName
				}
			}
		}
	`

	export const GET_ARTICLES = gql`
		query GetArticles {
			getArticles {
				collection {
					items {
						id
						title
						isPublished
					}
				}
			}
		}
	`

	export const GET_COUNTRIES = gql`
		query GetCountries {
			getCountries {
				collection {
					items {
						id
						isoCode
						slug
						isPublished
					}
				}
			}
		}
	`

	export const GET_ARTICLE_STATUS = gql`
		query GetArticleForAdmin($id: Int!) {
			getArticleForAdmin(id: $id) {
				success
				article {
					${Fragments.ArticleFragment}
				}
			}
		}
	`

	export const GET_COUNTRY_STATUS = gql`
		query GetCountryForAdmin($id: Int!) {
			getCountryForAdmin(id: $id) {
				success
				country {
					${Fragments.CountryFragment}
				}
			}
		}
	`

	export const GET_GUIDE_TRIP_INSIGHTS = gql`
		query GetGuideTripInsights {
			getGuideTripInsights {
				success
				insights {
					highLevelStats {
						priceDifferenceToLowest
						priceDifferenceToMedian
					}
					tripInsights {
						tripId
						currentPrice
						medianPrice
						lowestPrice
						highestPrice
						otherGuidesCount
						deltaFromLowest
						deltaFromMedian
					}
				}
			}
		}
	`

	export namespace Guide {
		export const GET_ALL_CERTIFICATIONS = gql`
			query GuidesGetAllCertificates {
				guidesGetAllCertificates {
					success
					error
					certificates {
						id
						imageId
						certificateName
						isApproved
					}
				}
			}
		`
	}
	export namespace Admin {
		export const GET_ALL_CERTIFICATIONS = gql`
			query AdminGetAllCertificates(
				$adminGetAllCertificatesInput: AdminGetAllCertificatesInput!
			) {
				adminGetAllCertificates(
					adminGetAllCertificatesInput: $adminGetAllCertificatesInput
				) {
					success
					error
					certificates {
						id
						imageId
						certificateName
						status
						guideCompanyId
						guideCompanyName
						createdAt
					}
				}
			}
		`
	}
}

export namespace Mutations {
	export const UpdateWhenToGo = gql`
		mutation UpdateWhenToGo($id: Int!, $updateObject: WhenToGoUpdates!) {
			updateWhenToGo(id: $id, updateObject: $updateObject) {
				...TrekFields
			}
		}
		${Fragments.TrekFragment}
	`
	export const UpdateTrek = gql`
		mutation UpdateTrek($id: Int!, $updatedTrek: UpdatedTrek!) {
			updateTrek(id: $id, updatedTrek: $updatedTrek) {
				updatedTrek {
					${Fragments._TrekFields}
					missingRequiredHoldingFields {
						field
						editSection
					}
					missingRequiredFields {
						field
						editSection
					}
					missingNonRequiredFields {
						field
						editSection
					}
					# todo: where are deltas?
					deltas {
						draft {
							${Fragments.JSONDataFragment}
						}
						live {
							${Fragments.JSONDataFragment}
						}
					}
				}
			}
		}
	`

	export const DELETE_FILE = gql`
		mutation DeleteUpload($id: Int!) {
			deleteUpload(id: $id) {
				operation {
					success
					trek {
						...TrekFields
					}
				}
			}
		}
		${Fragments.TrekFragment}
	`

	export const UPLOAD_FILE = gql`
		mutation UploadFile(
			$encodedFile: String!
			$fileName: String!
			$source: String!
			$attribution: String
			$tripId: Int
			$resourceId: Int
			$resourceType: ResourceType!
			$version: String!
			$isDisplayedInGallery: Boolean
		) {
			uploadFile(
				encodedFile: $encodedFile
				fileName: $fileName
				source: $source
				attribution: $attribution
				tripId: $tripId
				resourceId: $resourceId
				resourceType: $resourceType
				version: $version
				isDisplayedInGallery: $isDisplayedInGallery
			) {
				operation {
					success
					error
					trek {
						...TrekFields
					}
				}
			}
		}
		${Fragments.TrekFragment}
	`

	export const UPLOAD_USER_FILE = gql`
		mutation UploadUserFile(
			$tripSlug: String!
			$storageKey: String!
			$fileName: String!
			$version: String!
			$resourceId: Int
			$resourceType: ResourceType!
			$isApproved: Boolean
			$source: String
			$attribution: String
			$attributionLink: String
		) {
			uploadUserFile(
				tripSlug: $tripSlug
				storageKey: $storageKey
				fileName: $fileName
				version: $version
				resourceId: $resourceId
				resourceType: $resourceType
				isApproved: $isApproved
				source: $source
				attribution: $attribution
				attributionLink: $attributionLink
			) {
				success
				error
				myUploads {
					id
					isApproved
				}
				newUploadId
			}
		}
	`

	export const IMPORT_FILE = gql`
		mutation ImportFile(
			$fileURL: String!
			$attribution: String
			$attributionLink: String
			$tripId: Int
			$resourceId: Int
			$resourceType: ResourceType!
			$version: String!
			$isDisplayedInGallery: Boolean
		) {
			importFile(
				fileURL: $fileURL
				attribution: $attribution
				attributionLink: $attributionLink
				tripId: $tripId
				resourceId: $resourceId
				resourceType: $resourceType
				version: $version
				isDisplayedInGallery: $isDisplayedInGallery
			) {
				operation {
					success
					error
					trek {
						...TrekFields
					}
				}
			}
		}
		${Fragments.TrekFragment}
	`

	export const UPDATE_UPLOADS_ORDER = gql`
		mutation UpdateUploadsOrder($newOrders: [UploadOrder!]!, $slug: String!) {
			updateUploadsOrder(newOrders: $newOrders, slug: $slug) {
				operation
			}
		}
	`

	export const LOGIN = gql`
		mutation Login($email: String!, $password: String!) {
			login(email: $email, password: $password) {
				token
				user {
					id
					role
				}
				error
			}
		}
	`

	export const REGISTER = gql`
		mutation Register($email: String!, $password: String!) {
			register(email: $email, password: $password) {
				token
				user {
					id
					role
				}
				error
			}
		}
	`

	export const REGISTER_AS_GUIDE = gql`
		mutation RegisterAsGuide(
			$email: String!
			$password: String!
			$companyName: String!
			$website: String!
		) {
			registerGuide(
				email: $email
				password: $password
				companyName: $companyName
				website: $website
			) {
				token
				user {
					id
					role
				}
				error
			}
		}
	`

	export const CREATE_TREK = gql`
		mutation NewTrek($name: String!, $trekOrClimb: String!) {
			newTrek(name: $name, trekOrClimb: $trekOrClimb)
		}
	`

	export const UpdateUploadsUIProperties = gql`
		mutation UpdateUploadUIProperties(
			$id: Int!
			$updateObject: UpdatedUpload!
			$slug: String
		) {
			updateUploadUIProperties(
				id: $id
				updateObject: $updateObject
				slug: $slug
			) {
				success
			}
		}
	`

	export const SubmitFeedback = gql`
		mutation Feedback($email: String, $message: String!) {
			feedback(email: $email, message: $message)
		}
	`

	export const SuggestEdit = gql`
		mutation SuggestEdit(
			$userId: Int!
			$page: String!
			$site: String!
			$suggestion: String!
		) {
			suggestEdit(
				userId: $userId
				page: $page
				site: $site
				suggestion: $suggestion
			)
		}
	`

	export const ResetUserPasswordById = gql`
		mutation ResetUserPasswordById($id: Int!) {
			resetUserPasswordById(id: $id)
		}
	`

	export const ResetUserPassword = gql`
		mutation ResetUserPassword($email: String!) {
			resetUserPassword(email: $email)
		}
	`

	export const ResendUpboardInvite = gql`
		mutation ResendUpboardInvite(
			$resendUpboardInviteInput: ResendUpboardInviteInput!
		) {
			resendUpboardInvite(resendUpboardInviteInput: $resendUpboardInviteInput) {
				success
				error
			}
		}
	`

	export const HandlePasswordReset = gql`
		mutation HandlePasswordReset($uuid: String!, $password: String!) {
			handlePasswordReset(uuid: $uuid, password: $password) {
				token
				user {
					id
					role
				}
			}
		}
	`

	export const CREATE_ADMIN_USER = gql`
		mutation CreateAdminUser($email: String!) {
			createAdminUser(email: $email) {
				error
				success
			}
		}
	`

	export const CREATE_ARTICLE = gql`
		mutation CreateArticle($version: String!, $title: String!) {
			createArticle(version: $version, title: $title) {
				success
			}
		}
	`

	export const CREATE_COUNTRY = gql`
		mutation CreateCountry($version: String!, $isoCode: String!) {
			createCountry(version: $version, isoCode: $isoCode) {
				success
				error
			}
		}
	`

	export const UpdateTripPublishedProps = gql`
		mutation UpdateTripPublishedData(
			$tripId: Int!
			$publishedDataUpdateObject: PublishedDataUpdateObjectInput!
		) {
			updateTripPublishedData(
				tripId: $tripId
				publishedDataUpdateObject: $publishedDataUpdateObject
			) {
				success
			}
		}
	`

	export const PromoteTripDraft = gql`
		mutation PromoteTripDraft($tripId: Int!) {
			promoteDraft(tripId: $tripId) {
				success
			}
		}
	`

	export const ApprovePendingUpload = gql`
		mutation ApprovePendingUpload($uploadId: Int!) {
			approvePendingUpload(uploadId: $uploadId) {
				operation
			}
		}
	`

	export const DeletePendingUpload = gql`
		mutation DeletePendingUpload($uploadId: Int!) {
			deletePendingUpload(uploadId: $uploadId)
		}
	`
	export const UpdateArticle = gql`
		mutation UpdateArticle($id: Int!, $updatedArticle: UpdatedArticleInput!) {
			updateArticle(id: $id, updatedArticle: $updatedArticle) {
				updatedArticle {
					${Fragments.ArticleFragment}
				}
			}
		}
	`

	export const UpdateCountry = gql`
		mutation UpdateCountry($id: Int!, $updatedCountry: UpdatedCountryInput!) {
			updateCountry(id: $id, updatedCountry: $updatedCountry) {
				updatedCountry {
					${Fragments.CountryFragment}
				}
			}
		}
	`

	export const CreateGuideTrip = gql`
		mutation CreateGuideTrip($createGuideTripInput: CreateGuideTripInput!) {
			createGuideTrip(createGuideTripInput: $createGuideTripInput) {
				success
				error
				newGuideTrips {
					id
					guideCompanyId
					tripId
					price
					currency
					webLink
				}
				guideScore {
					score
					possibleImprovements {
						value
						category
					}
				}
			}
		}
	`

	export const RemoveGuideTrip = gql`
		mutation RemoveGuideTrip($removeGuideTripInput: RemoveGuideTripInput!) {
			removeGuideTrip(removeGuideTripInput: $removeGuideTripInput) {
				success
				error
				newGuideTrips {
					id
					guideCompanyId
					tripId
					price
					currency
					webLink
				}
				guideScore {
					score
					possibleImprovements {
						value
						category
					}
				}
			}
		}
	`

	export const UpsertGuideTripMultiPeak = gql`
		mutation UpsertGuideTripMultiPeak(
			$upsertGuideTripMultiPeakInput: UpsertGuideTripMultiPeakInput!
		) {
			upsertGuideTripMultiPeak(
				upsertGuideTripMultiPeakInput: $upsertGuideTripMultiPeakInput
			) {
				success
				error
				newTrips {
					id
					price
					currency
					webLink
					trips {
						${Fragments._TrekFields}
					}
				}
			}
		}
	`

	export const RemoveGuideTripMultiTrip = gql`
		mutation RemoveGuideTripMultiTrip(
			$removeGuideTripMultiPeakInput: RemoveGuideTripMultiPeakInput!
		) {
			removeGuideTripMultiPeak(
				removeGuideTripMultiPeakInput: $removeGuideTripMultiPeakInput
			) {
				success
				error
				newTrips {
					id
					price
					currency
					webLink
					trips {
						${Fragments._TrekFields}
					}
				}
			}
		}
	`
	export namespace Guide {
		// todo: guide dashboard: mutation to add new certification
		export const ADD_NEW_CERTIFICATION = gql`
			mutation SubmitNewCertificate(
				$submitNewCertificateInput: SubmitNewCertificateInput!
			) {
				submitNewCertificate(
					submitNewCertificateInput: $submitNewCertificateInput
				) {
					success
					error
				}
			}
		`
		// todo: guide dashboard: mutation to remove certification
		export const REMOVE_CERTIFICATION = gql`
			mutation RemoveCertificate(
				$removeCertificateInput: RemoveCertificateInput!
			) {
				removeCertificate(removeCertificateInput: $removeCertificateInput) {
					success
					error
				}
			}
		`
	}
	export namespace Admin {
		// todo: admin dashboard: mutation to approve a certification
		export const APPROVE_PENDING_CERTIFICATION = gql`
			mutation ApproveCertificate(
				$approveCertificateInput: ApproveCertificateInput!
			) {
				approveCertificate(approveCertificateInput: $approveCertificateInput) {
					success
					error
				}
			}
		`
		// todo: admin dashboard: mutation to reject a certification
		export const REJECT_PENDING_CERTIFICATION = gql`
			mutation RejectCertificate(
				$rejectCertificateInput: RejectCertificateInput!
			) {
				rejectCertificate(rejectCertificateInput: $rejectCertificateInput) {
					success
					error
				}
			}
		`
	}
}
